//default
$breakpoint-md : 768px;
@mixin media-breakpoint-md {
    @media (min-width: $breakpoint-md) {
        @content;
    }
}

$breakpoint-lg : 992px;
@mixin media-breakpoint-lg {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}

$breakpoint-xl : 1200px;
@mixin media-breakpoint-xl {
    @media (min-width: $breakpoint-xl) {
        @content;
    }
}

$breakpoint-xxl : 1400px;
@mixin media-breakpoint-xxl {
    @media (min-width: $breakpoint-xxl) {
        @content;
    }
}

// down
$breakpoint-down-md : 767.98px;
@mixin media-breakpoint-md-down {
    @media (max-width: $breakpoint-down-md) {
        @content;
    }
}

$breakpoint-down-lg : 991.98px;
@mixin media-breakpoint-lg-down {
    @media (max-width: $breakpoint-down-lg) {
        @content;
    }
}

// Login Page Specific