.login.login-1 .login-aside .aside-img {
  min-height: 450px; }

.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1 .login-forgot {
  display: none; }

.login.login-1.login-signin-on .login-signup {
  display: none; }

.login.login-1.login-signin-on .login-signin {
  display: block; }

.login.login-1.login-signin-on .login-forgot {
  display: none; }

.login.login-1.login-signup-on .login-signup {
  display: block; }

.login.login-1.login-signup-on .login-signin {
  display: none; }

.login.login-1.login-signup-on .login-forgot {
  display: none; }

.login.login-1.login-forgot-on .login-signup {
  display: none; }

.login.login-1.login-forgot-on .login-signin {
  display: none; }

.login.login-1.login-forgot-on .login-forgot {
  display: block; }

@media (min-width: 992px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 600px; }
  .login.login-1 .login-content {
    width: 100%;
    max-width: 500px; }
    .login.login-1 .login-content .login-form {
      width: 100%;
      max-width: 450px; } }

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px; } }

@media (max-width: 991.98px) {
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 400px; } }

@media (max-width: 575.98px) {
  .login.login-1 .aside-img {
    min-height: 300px !important;
    background-size: 400px; }
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 100%; } }


    .pt-10,
    .py-10 {
      padding-top: 2.5rem !important; }

      @media (max-width: 991.98px) {
        .btn-m-block{
          float: none !important;
        }
        .btn-m-block .btn.btn-primary{
          display: block !important;
          width: 100%;
          margin-left: 0 !important;
        }
    }