// scss
&.v3 {

    color: #fff;

    // login-aside
    .login-aside {
        background-color: #01033A;

        .brand-logo {
            max-width: 200px !important;
            margin-bottom: 48px;
        }
    }

    // login-content
    .login-content {
        color: #333333;
    }

}