.fw-500 {
    font-weight: 500;
}

.cursor-move {
    cursor: move;
}

// mixins
@import './mixins/breakpoints';

// plugins
@import './custom/plugins/react-flatpickr/react-flatpickr';

// Custom
@import url(./custom/_custom.scss);

// dummy commit 2