.card-spacer {
  padding: 2rem 2.25rem !important; }

.card-spacer-x {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important; }

.card-spacer-y {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }
