.stats-break-down {
    .stats {
        .col-auto {
            min-width: 50%;
            margin-bottom: 1.25rem !important;
        }
    }
    &.lay-vertical {
        .stats {
            margin-bottom: 0 !important;
            .col-auto {
                min-width: 100%;
            }
        }
        canvas{
            display: none !important;
        }
    }
}
