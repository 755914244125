.c-separator {
  .sub-text {
    display: block;
    color: #B5B5C3;
  }
    &.color-charcoal{
      color: rgba($color: #333, $alpha: 1.0);
      hr {
        border-color: rgba($color: #333, $alpha: 1.0);
      }
    }
    &.v2{
      font-size: 21px;
      strong{
        font-weight: 600;
      }
      .sub-text {
        font-weight: 500;
        font-size: 1.35rem;
      }
    }
  }