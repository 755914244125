// Manage Addons

.pages {
    &.manage-addons {
        color: #48465b;
        padding-bottom: 32px;

        .addon {
            .card {
                border-radius: 8px;
                height: calc(100% - 13px);

                .columns {
                    .icon {
                        background-color: #e1e1e1;
                        min-width: 86px;
                        min-height: 86px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            min-width: 40px;
                            min-height: 40px;
                        }
                    }

                    .content {
                        flex-grow: 1;

                        h4 {
                            display: flex;
                            align-items: center;

                            .btn {
                                padding: 1px 6px;
                            }
                        }

                        p {
                            margin-bottom: 0;
                            font-size: 15px;
                        }
                    }

                    .button {
                        min-width: 110px;
                        text-align: right;

                        .btn {
                            min-width: 110px;

                            span {
                                &.hover {
                                    display: none;
                                }
                            }

                            &:hover {
                                span {
                                    &.hover {
                                        display: block;
                                    }

                                    &.default {
                                        display: none;
                                    }
                                }
                            }

                            &.btn-primary {
                                background-color: #3699ff;
                                border-color: #3699ff;

                                &:hover {
                                    background-color: #0abb87;
                                    border-color: #0abb87;
                                }
                            }

                            &.btn-success {
                                &:hover {
                                    background-color: #3699ff;
                                    border-color: #3699ff;
                                }
                            }
                        }
                    }
                }

                .addons-item-price {
                    text-align: center;
                    font-size: 12px;
                }

                .addons-item-icon {
                    text-align: center;
                }

                .addons-item-premium {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: white;
                    font-size: 10px;
                    padding-bottom: 0px;
                    padding-top: 4px;
                }
            }
        }

        .card-border {
            border-bottom-style: solid;
            border-bottom-width: 2px;
        }

        .card-border-warning {
            border-bottom-color: #ffb822;
            opacity: 0.6;
        }

        .card-border-brand {
            border-bottom-color: #333;
        }

        // subscription-v2
        &.subscription-v2 {

        }
    }
}
